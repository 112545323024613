import '../styles/landingpage.scss';
import $ from 'jquery';
import 'bootstrap';
import 'jquery-ui-bundle/jquery-ui';
import 'jquery.easing/jquery.easing.1.3';
import MobileEsp from './components/mdetect';

const initSharePage = () => {
    const page_id = $('body').attr('id');
	if (page_id != 'share_liveshow') return;

    if (MobileEsp.DetectAndroid()) {
        $('#download_google').show();
    }
    if (MobileEsp.DetectIos()) {
        $('#download_apple').show();
    }
    if (!MobileEsp.DetectAndroid() && !MobileEsp.DetectIos()) {
        $('#download_google').show();
        $('#download_apple').show();
    }
};

const togglePasswordVisibility = () => {
    const toggle = $('.toggle-password');
    toggle.on('click', function () {
        const password = $(this).closest('fieldset').find('input[name=password]');
        const type = password.attr('type') == 'password' ? 'text' : 'password';
        password.attr('type', type);
        $(this).toggleClass('fa-eye fa-eye-slash');
    });
};

$(function () {
    $("[data-toggle=collapse]").on('click', function () {
        let tabpane = $(this).data('tabpane');
        $(tabpane).find('.accordion .card').removeClass('opened');
        if ($(this).hasClass('collapsed')) {
            $(this).closest('.card').addClass('opened');
        }
    });

    $('.faq-table-of-contents a').on('shown.bs.tab', function() {
        $('html, body').animate({ scrollTop: 0 }, 'slow');
    });

    $(document).on('click', function(event) {
        var dropdownSearch = $('.dropdown-search:visible');
    
        // Check if the clicked element is inside the visible .dropdown-search
        if (!dropdownSearch.length || !$(event.target).closest('.dropdown-search').length) {
            // If the click is outside of the visible .dropdown-search, hide all .dropdown-search elements
            $('.dropdown-search').hide();
            $('.btn-up-search').html('<i class="icon-chevron-down"></i>');
        }
    });
    
    $('.multiple-checkbox').on('click', function(event) {
        var dropdownSearch = $(this).closest('fieldset').find('.dropdown-search');
    
        // Toggle visibility of the dropdown-search
        if (dropdownSearch.is(':hidden')) {
            // If hidden, show it
            $('.dropdown-search').hide();
            $('.btn-up-search').html('<i class="icon-chevron-up"></i>');
            dropdownSearch.show();
            dropdownSearch.closest('.input-group').find('.btn-up-search').html('<i class="icon-chevron-down"></i>');
        } else {
            // If visible, hide it
            dropdownSearch.hide();
            $('.btn-up-search').html('<i class="icon-chevron-down"></i>');
            dropdownSearch.closest('.input-group').find('.btn-up-search').html('<i class="icon-chevron-up"></i>');
        }
    
        // Prevent the click event from propagating to the document, so it doesn't trigger the document click event
        event.stopPropagation();
    });
    
    $('.dropdown-search').on('click', function(event) {
        // Prevent the click event from propagating to the document, so it doesn't trigger the document click event
        event.stopPropagation();
    });
    

    // Select category
    $('input[name="category"]').change(function() {
        var selectedCategories = [];
        $('input[name="category"]:checked').each(function() {
            selectedCategories.push($(this).val());
        });
        $('#filter_category').val(selectedCategories.join(', '));
    });

    // Select platform
    $('input[name="platform"]').change(function() {
        var selectedCategories = [];
        $('input[name="platform"]:checked').each(function() {
            selectedCategories.push($(this).val());
        });
        $('#filter_platform').val(selectedCategories.join(', '));
    });

    // Event handler for the form submission
    $('#seller-application button').on('click', function () {
        let isValid = true;
        let businessName = $("input[name='business_name']").val();
        let website = $("input[name='website']").val();
        let businessCategories = $("input[name='business_categories']").val();
        let businessPlatforms = $("input[name='business_platforms']").val();
        let isCheckedYes = $("input[name='social_media'][value='yes']").is(":checked");
        let isCheckedNo = $("input[name='social_media'][value='no']").is(":checked");

        let instagram = $("input[name='instagram']").val();
        let facebook = $("input[name='facebook']").val();
        let tiktok = $("input[name='tiktok']").val();
        let youtube = $("input[name='youtube']").val();

        if ( businessName === '') {
            $("span[name='business_name_warning']").text("* This field is required.");
            isValid = false;
        } else {
            $("span[name='business_name_warning']").text("");
        }

        if ( website === '') {
            $("span[name='website_warning']").text("* This field is required.");
            isValid = false;
        } else {
            $("span[name='website_warning']").text("");
        }

        if ( businessCategories === '' ) {
            $("span[name='business_categories_warning']").text("* This field is required.");
            isValid = false;
        } else {
            $("span[name='business_categories_warning']").text("");
        }

        if ( businessPlatforms === '' ) {
            $("span[name='business_platforms_warning']").text("* This field is required.");
            isValid = false;
        } else {
            $("span[name='business_platforms_warning']").text("");
        }

        if ( 
            isCheckedYes === true && 
            instagram === '' &&
            facebook === '' &&
            tiktok === '' &&
            youtube === ''
        ) {
            $("span[name='instagram_warning']").text("* This field is required.");
            $("span[name='facebook_warning']").text("* This field is required.");
            $("span[name='tiktok_warning']").text("* This field is required.");
            $("span[name='youtube_warning']").text("* This field is required.");
            isValid = false;
        } else {
            $("span[name='instagram_warning']").text("");
            $("span[name='facebook_warning']").text("");
            $("span[name='tiktok_warning']").text("");
            $("span[name='youtube_warning']").text("");
        }

        if (isValid) {
            // Show register
            $('#seller-application').hide();
            $('#register').removeClass('d-none');   
        }
    });

    $('.create-account-btn').on('click', function () {
        $(this).text('creating account...').attr('disabled', 'disabled');
        $(this).closest('form').submit();
    })
    
    initSharePage();
    togglePasswordVisibility();
});